import createPermissionsForUser from './access/createPermissionsForUser'
import deletePermissionsForUser from './access/deletePermissionsForUser'
import getAllUserPermissions from './access/getAllUserPermissions'
import getUserPermissions from './access/getUserPermissions'
import updatePermissionsForUser from './access/updatePermissionsForUser'
import acceptAccount from './accounts/acceptAccount'
import rejectAccount from './accounts/rejectAccount'
import editAccount from './accounts/editAccount'
import createNewAccount from './accounts/createNewAccount'
import getAccounts from './accounts/getAccounts'
import getSingleAccount from './accounts/getSingleAccount'

import acceptgcpProject from './gcpProjects/acceptgcpProject'
import rejectgcpProject from './gcpProjects/rejectgcpProject'
import editgcpProject from './gcpProjects/editgcpProject'
import createNewgcpProject from './gcpProjects/createNewgcpProject'
import getgcpProjects from './gcpProjects/getgcpProject'
import getSinglegcpProject from './gcpProjects/getSinglegcpProject'

import acceptProject from './projects/acceptProject'
import createNewProject from './projects/createNewProject'
import editProject from './projects/editProject'
import getProjects from './projects/getProjects'
import getSingleProject from './projects/getSingleProject'
import rejectProject from './projects/rejectProject'

import createTags from './tags/createTags'
import getTagsById from './tags/getTagsById'
import updateMultiStepTagsById from './tags/updateMultiStepTagsById'
import approveTagsById from './tags/approveTagsById'
import updateTagsById from './tags/updateTagsById'
import getTagsByAccountId from './tags/getTagsByAccountId'

import getPlatforms from './platforms/getPlatforms'
import createNewPlatform from './platforms/createNewPlatform'
import editPlatform from './platforms/editPlatform'
import rejectPlatform from './platforms/rejectPlatform'
import acceptPlatform from './platforms/acceptPlatform'
import getSinglePlatform from './platforms/getSinglePlatform'

import getApplications from './applications/getApplication'
import getSingleApplication from './applications/getSingleApplication'
import rejectApplication from './applications/rejectApplication'
import editApplication from './applications/editApplication'
import createNewApplication from './applications/createNewApplication'
import acceptApplication from './applications/acceptApplication'
import getTagsByGCPProjectId from './tags/getTagsByGCPProjectId'

const api = {
	projects: {
		getProjects,
		getSingleProject,
		createNewProject,
		rejectProject,
		acceptProject,
		editProject
	},
	accessManagement: {
		getUserPermissions,
		getAllUserPermissions,
		createPermissionsForUser,
		updatePermissionsForUser,
		deletePermissionsForUser
	},
	accounts: {
		getAccounts,
		getSingleAccount,
		createNewAccount,
		acceptAccount,
		rejectAccount,
		editAccount
	},
	tags: {
		getTagsById,
		createTags,
		updateMultiStepTagsById,
		approveTagsById,
		updateTagsById,
		getTagsByAccountId,
		getTagsByGCPProjectId
	},
	platforms: {
		getPlatforms,
		getSinglePlatform,
		createNewPlatform,
		editPlatform,
		acceptPlatform,
		rejectPlatform
	},
	applications: {
		getApplications,
		getSingleApplication,
		createNewApplication,
		editApplication,
		acceptApplication,
		rejectApplication
	},
	gcpProjects: {
		getgcpProjects,
		getSinglegcpProject,
		createNewgcpProject,
		acceptgcpProject,
		rejectgcpProject,
		editgcpProject
	},
}

export default api
